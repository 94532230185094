<template>
    <div>
        <router-link :to="`/post/${ post._id }`" class="link_route">
            <article
                class="flex max-w-xl flex-col items-start justify-between border border-white hover:border-gray-200 p-6 md:p-4 lg:p-6 rounded-md">
                <div class="group relative">
                    <img :alt="post.title" class="rounded-md" v-if="post.image" :src="CreateURL(post.image, 480, 320)" />
                    <h3
                        class="mt-3 text-2xl md:text-sm lg:text-2xl font-semibold leading-6 md:leading-4 text-gray-900 group-hover:text-gray-600">
                        <a :href="post.href">
                            {{ post.title }}
                        </a>
                    </h3>
                    <p class="mt-6 md:text-base text-gray-900">
                        {{ post.excerpt }}
                    </p>
                    <div class="md:text-xs lg:text-base mt-5" v-if="post.categorie">
                        <a href="#" class="relative z-10 rounded-full bg-gray-100 px-3 py-1.5 md:p-2 lg:px-3 lg:py-1.5 font-medium text-black hover:bg-gray-200">
                            {{post.categorie.name }}
                        </a>
                    </div>
                </div>
                <div class="relative mt-8 flex items-center gap-x-4" v-if="post.author">
                    <img v-if="post.author.avatar" :src="CreateURL(post.author.avatar, 300, 300)" :alt="post.author.full_name" class="h-10 w-10 rounded-full bg-gray-50" />
                    <div class="text-base leading-6 md:text-xs lg:text-sm">
                        <p class="font-semibold text-gray-900">
                            <a href="#">
                                <span class="absolute inset-0" />
                                {{ post.author.full_name }}
                            </a>
                        </p>
                        <time :datetime="post.datetime" class="text-gray-500 text-xs">{{ FormatDate(post._createdAt) }}</time>
                    </div>
                </div>
            </article>
        </router-link>
    </div>
</template>

<script>
import { CreateURL, FormatDate } from '../../utils'

export default {
    props: {
        post: {
            type: Object,
            required: true
        }
    },
    setup() {
        return {
            CreateURL,
            FormatDate
        }
    }
}
</script>