<template>
    <div class="w-full fixed top-0  bg-white z-50">
        <div class="relative pt-10 pb-6 ">
            <div class="container mx-auto px-4 w-full flex justify-between items-center">
                <div class="flex gap-1 md:gap-3 items-center relative">
                    <a href="/">
                        <h1 class="md:pr-10 flex flex-col relative z-20 justify-center leading-[18px] items-center">
                            <span class="font-bold text-[18px] md:text-[20px] p-0">LAURÈNE</span>
                            <span class="text-md font-light text-[16px] p-O md:text-[18px]">BITOTA</span>
                        </h1>
                    </a>

                    <div>
                        <a href="/blog" class="border-l-2 px-2 border-red-500 dak:border-white text-base flex flex-col">
                            <span class="">Découvre</span>
                            <span>Mon Blog</span>
                        </a>
                    </div>
                </div>
                <div class="flex justify-between gap-4">
                    <SocialMedia />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import SocialMedia from '../components/Footers/SocialMedia.vue'
export default {
    data() {
        return {
        };
    },
    components: {
        SocialMedia

    },
};
</script>
