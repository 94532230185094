<template>
    <div>
        <!-- SEO Component -->
    </div>
</template>

<script>
export default {
    props: {
        meta: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.updateMeta();
    },
    watch: {
        meta: {
            handler() {
                this.updateMeta();
            },
            deep: true,
        },
    },
    methods: {
        updateMeta() {
            const meta = this.meta;
            if (!meta) return;

            document.title = meta.title || "Default Title";

            if (meta.metaTags && Array.isArray(meta.metaTags)) {
                meta.metaTags.forEach((tag) => {
                    const tagElement =
                        document.querySelector(`meta[name='${tag.name}']`) ||
                        document.querySelector(`meta[property='${tag.property}']`);
                    if (tagElement) {
                        tagElement.setAttribute("content", tag.content);
                    } else {
                        const newTag = document.createElement("meta");
                        if (tag.name) newTag.setAttribute("name", tag.name);
                        if (tag.property) newTag.setAttribute("property", tag.property);
                        newTag.setAttribute("content", tag.content);
                        document.head.appendChild(newTag);
                    }
                });
            }
        },
    },
};
</script>