import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/index.css";

import router from "./router";

import VueGtag from "vue-gtag";

// import { VueReCaptcha } from 'vue-recaptcha-v3';

import { install } from "vue3-recaptcha-v2";



createApp(App)
  .use(router)
  .use(VueGtag, {
    config: { id: "G-X4DZ48K3RQ" },
  })
  // .use(VueReCaptcha, { siteKey: '6Ler6dUpAAAAAAw0Nd_SvZVet7ihso3ue5ncjhWB' })
  .use(install, {
    sitekey: "6LcF3dQpAAAAABT2tZQ0qXgiUh_mPl5fvEyis0w2",
    cnDomains: false, // Optional, If you use in China, set this value true
  })
  .use(store)
  .mount("#app");
