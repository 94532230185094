<template>
  <div>
      <div class="social-links text-gray-700 flex gap-5">
          <a href="https://www.linkedin.com/in/laurene-bitota-714798199/" target="_blank">
            <i class=" fab fa-linkedin-in shadow-2xl w-9 h-9 border border-gray-500 p-2 text-center rounded-tl-xl rounded-br-lg hover:bg-black hover:text-white text-[20px]"></i>
          </a>
          <a href="https://twitter.com/bitotabkl" target="_blank">
            <i class="fas fa-times shadow-2xl w-9 h-9 border border-gray-500 p-2 text-center rounded-tl-xl rounded-br-lg hover:bg-black hover:text-white text-[20px]"></i>
          </a>
          <a href="https://www.instagram.com/laurene_bitota/" target="_blank">
            <i class="fab fa-instagram shadow-2xl w-9 h-9 border border-gray-500 p-2 text-center rounded-tl-xl rounded-br-lg hover:bg-black hover:text-white text-[20px]"></i>
          </a>
          <a href="https://github.com/laurene12" target="_blank">
            <i class="fab fa-github shadow-2xl w-9 h-9 border border-gray-500 p-2 text-center rounded-tl-xl rounded-br-lg hover:bg-black hover:text-white text-[20px]"></i>
          </a> 
          <a href="mailto:bitotalaurene@gmail.com" target="_blank">
            <i class="bx bxl-gmail shadow-2xl w-9 h-9 border border-gray-500 p-2 text-center rounded-tl-xl rounded-br-lg hover:bg-black hover:text-white text-[20px]"></i>
          </a>
        </div>
  </div>
</template>

