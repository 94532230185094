import sanity from './client'
import imageUrlBuilder from '@sanity/image-url'
const builder = imageUrlBuilder(sanity)

export const CreateURL = (source, width = 300, height = 300) => {
	return builder.image(source).width(width).height(height).url()
}

export const TextToHTML = (text) => {
    // Gras
    text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    
    // Italiques
    text = text.replace(/\*(.*?)\*/g, "<em>$1</em>");

    // Saut de lignes
    text = text.replace(/\n/g, "<br>");


    return text;
}



export const FormatDate = (date) => {
	return new Date(date).toLocaleDateString()
}