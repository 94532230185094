<template>
	<header class="w-full fixed top-0  bg-white z-50">
        <div class="relative pt-10 pb-6 ">
            <div class="container mx-auto px-4 w-full flex justify-between items-center">
                <div class="">
					<a href="/">
						<h1 class="md:pr-10 flex flex-col relative z-20 justify-center leading-[18px] items-start">
							<span class="font-bold text-[18px] md:text-[20px] p-0">LAURÈNE</span>
							<span class="text-md font-light text-[16px] p-O md:text-[18px]">BITOTA</span>
						</h1>
					</a>
                </div>
                <div class="">
					<div class="menu-toggle relative z-50 -mr-4" :class="{ 'is-active': $store.state.menu_is_active }"
					@click="$store.dispatch('ToggleMenu')">
					<div class="hamburger">
						<span></span>
					</div>
				</div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>

import "../assets/styles/scss/menu.scss";
export default {
	
	mounted() {

    this.$watch(
        () => this.$store.state.menu_is_active,
        (newValue) => {
            const mainElement = document.querySelector('main');
            if (newValue) {
                document.body.style.overflow = 'hidden';

                const overlay = document.createElement('div');
                overlay.classList.add('overlay-menu-opening');
                mainElement.appendChild(overlay);
            } else {
                document.body.style.overflow = 'auto'; 

                const overlay = mainElement.querySelector('.overlay-menu-opening');
                if (overlay) {
                    overlay.remove();
                }
            }
        }
    );
}



}
</script>




