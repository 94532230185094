import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeTemplate.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/BlogPage.vue"),
  },
  {
    path: "/post/:id",
    name: "Post",
    component: () => import("../views/post/_id.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Erreur",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  if (from.name) {
    document.documentElement.scrollTop = 0;

    store.dispatch("CloseMenu");
  }
});

export default router;
