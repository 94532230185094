<template>
    <div class="relative flex flex-wrap text-left bg-slate-100 p-8 lg:p-16 z-2">
        <div class="w-full lg:w-6/12 px-2 lg-px-4">
          <ul class="contact-infos text-sm md:text-base text-gray-800 font-semibold py-1 mb-3">
            <li class="mb-2">
              <a href="tel:+243812874074" target="_blank" class="hover:text-gray-950 text-gray-700">+243 81 28 74 074</a>
            </li>
            <li class="mb-2">
              <a href="mailto:bitotalaurene@gmail.com" target="_blank"
                class="hover:text-gray-950 text-gray-700">bitotalaurene@gmail.com</a>
            </li>
            <li class="mb-2 text-gray-700 text-sm">Kinshasa -RDC.</li>
          </ul>
          <!-- Social Media -->
          <SocialMedia/>
         
        </div>
      </div>
</template>
<script>
import "../../assets/styles/scss/footer.scss";
import SocialMedia from './SocialMedia.vue'

export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  components:{
    SocialMedia,
    
  },

};
</script>
