<template>
  <footer class="relative pt-10 pb-6">
    <div class="container mx-auto px-4 w-full">
      <div class="relative flex flex-wrap text-left p-0 md:p-8  lg:p-16">
        <!-- Description Go to CTA -->
        <div class="w-full md:w-5/12 lg:w-2/5  flex items-end px-4 md:px-1.5 xl:px-1.5">
          <p class="text-2xl lg:text-3xl mt-0 mb-2 text-gray-700 font-normal collaborations">
            Entamons une collaboration en créant des projets uniques, originaux et porteurs de sens. Que ce soit en
            explorant de nouvelles idées, des concepts novateurs ou des designs captivants.
            <span class="font-bold text-gray-950 text_hello text-3xl">
              Dites simplement, Bonjour !
            </span>
          </p>
        </div>

        <!-- Aroows Inner-section -->
        <div class="w-full md:w-2/12 lg:w-1/5 flex items-end px-4 invisible xl:visible">
          <div class="flex flex-wrap items-top mb-4 gap-3">
            <div class="before-arrow"></div>
            <div class="arrow"></div>
          </div>
        </div>

        <!-- Form inner-section -->
        <FormProject/>

      </div>
    
      <PersonnalInfos/>
      <CopyrightInfos/>
    </div>
  </footer>
</template>
<script>
import "../../assets/styles/scss/footer.scss";
import FormProject from './FormProject.vue'
import CopyrightInfos from './CopyrightInfos.vue'
import PersonnalInfos from './PersonnalInfos.vue'

export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
  components:{
    FormProject,
    CopyrightInfos,
    PersonnalInfos

  },
};
</script>
