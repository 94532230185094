<template>

    <section>
            <div class="container mx-auto px-4 w-full">
                <div class="relative flex flex-wrap text-left p-0 md:p-8 lg:p-16">
                <div class="mx-auto max-w-3xl text-center">
                    <h2 class="mt-2 text-3xl xl:text-4xl font-bold tracking-tight lg:mb-8 text-gray-900" >
                    Dernières nouvelles
                    </h2>
                    <p class="mt-6 md:text-base text-gray-900">
                    Découvrez les coulisses de ma success story en tant que développeuse,
                    où des nouvelles récentes mettent en évidence mes accomplissements,
                    les défis surmontés, mon quotidien professionnel, mon parcours et les
                    dernières tendances digitales.
                    </p>
                </div>

                <!-- News-->
                <div class="mt-3">
                    <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-3 xl:gap-x-8">
                        
                        <NewsCard v-for="(post, i) in posts" :key="i" :post="post"/>
                    </div>
                </div>
                </div>
            </div>
    </section>

</template>

<script>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import sanity from '../client'

import NewsCard from '../components/Card/NewsCard';

export default {
    components: {
        NewsCard
    },
    setup() {
        const subscription = ref(null)
        const store = useStore()

        const posts = computed(() => store.getters.posts)

        onMounted(() => {
            store.dispatch("FetchPosts", 3)

            const query = '*[_type == "post"]'

            subscription.value = sanity
                .listen(query)
                .subscribe(update => {
                    switch (update.transition) {
                        case "update":
                            sanity.getDocument(update.result.author._ref).then(author => {
                                store.dispatch("UpdatePost", {
                                    ...update.result, author
                                })
                            })
                            break;

                        case "appear":
                            sanity.getDocument(update.result.author._ref).then(author => {
                                store.dispatch("AddNewPost", {
                                    ...update.result, author
                                })
                            })
                            break;

                        case "disappear":
                            store.dispatch("RemovePost", update.documentId)
                            break;
                    }

                })
        })

        onUnmounted(() => {
            subscription.value.unsubscribe();
        })

        return {
            posts
        }
    }
}
</script>
