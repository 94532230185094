<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <div class="container mx-auto px-4 w-full pb-4 -mb-16">
      <div class="flex flex-col md:flex-row px-4 lg:px-16 gap-8">
        <!-- Image -->
        <div class="w-full md:w-1/2"> 
          <!-- <h2 class="text-center md:text-left mt-2 text-3xl md:text-3xl xl:text-4xl font-bold tracking-tight lg:mb-8 text-gray-900 block md:hidden">
            👋 Salut, je m'appelle Laurène Bitota
          </h2>  -->
          <img class="mt-8 md:mt-0 h-100 w-full object-cover object-center rounded-lg"
            :src="require(`../../assets/imgs/laurenebitota.png`)" alt="about" loading="lazy" />
        </div>
        <!-- Descriptions -->
        <div class="w-full md:w-1/2 flex flex-col justify-center">
          <h2 class="text-center md:text-left mt-2 text-3xl md:text-3xl xl:text-4xl font-bold tracking-tight lg:mb-8 text-gray-900">
            👋 Salut, je m'appelle Laurène Bitota
          </h2>
          <div class="text-center md:text-left">
            <p class="mt-6 md:text-base text-gray-900">
              Je suis Developpeuse web frontend, passionnée par les
              tendances numériques, UI & UX et la gestion de projets.
            </p>
            <p class="mt-6 md:text-base text-gray-900">
              Mon
              expertise réside dans la création d'interfaces numériques
              innovantes et intuitives. En me concentrant sur les besoins des utilisateurs, je m'assure
              que chaque élément visuel et fonctionnalité contribue à une
              expérience en ligne optimale.
            </p>
            <p class="mt-6 md:text-base text-gray-900">
              Mon approche allie design et fonctionnalité pour garantir des
              sites web à la fois attrayants et performants. 💻🎨🚀
            </p>
          </div>

          <div
            class="mt-10 flex flex-col md:flex-row items-center gap-3 md:items-center justify-center gap-x-6 md:justify-start">
            <a href="mailto:bitotalaurene@gmail.com"
              class="rounded-none uppercase bg-black px-3.5 py-2.5 text-white text-sm font-semibold border border-black hover:bg-white hover:text-black hover:border-black transition duration-300">
              Contactez-moi <span class="ml-1">&#8594;</span>
            </a>
         
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import gsap from "gsap";

export default {
  methods: {
    beforeEnter(el) {
      el.style.transform = "translateY(-100px)";
    },
    enter(el) {
      gsap.to(el, {
        duration: 1.7,
        ease: "bounce.out",
        y: 0,
      });
    },
  },
};
</script>
