<template>
    <div class="flex flex-wrap items-center md:justify-between justify-center pt-4 lg:pt-8">
        <div class="w-full mx-auto text-center">
          <div class="text-xs md:text-[14px] text-gray-700 font-bold py-1">
            Copyright © 2024 - 
            <a href="https://www.linkedin.com/in/laurene-bitota-714798199/" class="text-gray-700 hover:text-gray-950"
              target="_blank">
              Laurène Bitota
            </a>
            -All rigths reserved.
          </div>
        </div>
      </div>
</template>
<script>
import "../../assets/styles/scss/footer.scss";


export default {
  data() {
    return {
    
    };
  },
  components:{
    
  },

};
</script>