<template>
    <div class="container mx-auto px-4 w-full mt-16 md:mt-0">
      <div class="relative flex flex-wrap text-left p-0 md:p-8 lg:p-16">

        <div class="mx-auto max-w-2xl text-center mb-4 xl:max-w-2xl">
          <h2 class="mt-2 text-3xl md:text-3xl xl:text-4xl font-bold tracking-tight lg:mb-8 text-gray-900">
            Decouvrez ce que je sais faire
          </h2>
          <p class="mt-6 md:text-base text-gray-900">
            Parcourez cette section pour découvrir en détail les compétences clés
            et les technologies qui composent ma boîte à outils professionnelle.
          </p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-full">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none md:grid-cols-2 lg:gap-y-16 md:max-w-3xl">
            <div v-for="feature in features" :key="feature.name" class="relative pl-16">
              <dt class="text-lg lg:text-2xl  md:text-base font-semibold text-gray-900">
                <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-black">
                  <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                {{ feature.name }}
              </dt>
              <dd class="lg:mt-4 mt-2 lg:mr-20 md:text-base text-gray-900">{{ feature.description }}</dd>
            </div>
          </dl>
        </div>

      </div>
    </div>
  </template>
  
  <script setup>
  import {  CodeBracketIcon,  LockClosedIcon, UsersIcon, WrenchScrewdriverIcon } from '@heroicons/vue/24/outline'
  
  const features = [
    {
      name: 'Développement Web',
      description:
        "Spécialisé en développement web, je construis des sites réactifs et performants pour répondre à vos besoins avec excellence.",
      icon: CodeBracketIcon,
    },
    {
      name: 'UI & UX',
      description:
        "Je crée des expériences utilisateur intuitives et esthétiques pour maximiser l'engagement et la satisfaction des utilisateurs.",
      icon: UsersIcon,
    },
    {
      name: 'Gestion de projets',
      description:
        "Je coordonne efficacement les équipes pour assurer des livraisons de qualité dans les délais impartis. Tout en assurant la satisfaction client.",
      icon: LockClosedIcon,
    },
    {
      name: 'Gestion & Audit des sites web',
      description:
        "Dotée de compétences en gestion et audit des sites web, j'optimise leur performance et leur sécurité pour garantir une expérience utilisateur optimale.",
      icon: WrenchScrewdriverIcon,
    },
  ]
  </script>