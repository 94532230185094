<template>
  <div class="container mx-auto px-4 w-full">
    <div class="relative flex flex-wrap text-left p-0 md:p-8 lg:px-16">
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-full">
        <div
          class="mx-auto mt-16 md:mt-20 xl:mt-0 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <div class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" width="158" height="48">
            <div class="flex font-semibold items-center justify-center">
              <i class='bx bxl-vuejs xl:text-6xl text-4xl'></i>
              <h3 class="xl:text-2xl text-xl">VueJs</h3>
            </div> 
          </div>
          <div class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" width="158" height="48">
            <div class="flex font-semibold items-center gap-1 justify-center">
              <i class='bx bxl-nodejs xl:text-4xl text-3xl text-white rounded-md bg-black xl:p-1' ></i>
              <h3 class="xl:text-2xl text-xl">nodejs</h3>
            </div>  
          </div>
          <div class="col-span-2 max-h-12 w-full object-contain lg:col-span-1" width="158" height="48">
            <div class="flex font-semibold items-center gap-1 justify-center">
              <i class='bx bxl-php xl:text-5xl text-4xl text-white bg-black -rotate-[10deg] rounded-xl' ></i>
              <h3 class="xl:text-2xl text-xl">PHP</h3>
            </div> 
          </div>
          <div class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1" width="158" height="48">
            <div class="flex font-semibold items-center gap-1 justify-center">
              <i class='bx bxl-wordpress xl:text-6xl text-4xl' ></i>
              <h3 class="xl:text-2xl text-xl">WordPress</h3>
            </div> 
          </div>
          <div class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1" width="158"
            height="48">
            <div class="flex font-semibold items-center gap-1 justify-center">
              <h4 class='xl:text-2xl text-xl text-white rounded-md bg-black xl:py-2 xl:px-2 p-1' >JS</h4>
              <h3 class="xl:text-2xl text-xl">javascript</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
