<template>
	<aside :class="`fixed top-0 right-0  pt-16 z-40 w-full max-w-full transform duration-300 bg-white text-black h-full ${$store.state.menu_is_active
			? ''
			: 'translate-x-full'}`
		">
		<div class="p-4 pt-12 flex flex-col h-full justify-between">
			<div class="-mx-4">
				<MenuItem to="/" class="border-0 border-b hover:tracking-wide">
					Accueil
				</MenuItem>
				<MenuItem to="/blog" class="border-0 border-b hover:tracking-wide">
					Découvre mon blog
				</MenuItem>
			</div>
			<SocialMedia class="" />
		</div>
	</aside>
</template>

<script>
import MenuItem from './MenuItem'
import SocialMedia from '../components/Footers/SocialMedia.vue'

export default {
	components: {
		MenuItem,
		SocialMedia
	},
	mounted() {
    document.body.style.overflow = 'auto'; // Activer le scroll par défaut au chargement de la page

    this.$watch(
        () => this.$store.state.menu_is_active,
        (newValue) => {
            const mainElement = document.querySelector('main');
            if (newValue) {
                document.body.style.overflow = 'hidden';

                const overlay = document.createElement('div');
                overlay.classList.add('overlay-menu-opening');
                mainElement.appendChild(overlay);
            } else {
                document.body.style.overflow = 'auto'; 

                const overlay = mainElement.querySelector('.overlay-menu-opening');
                if (overlay) {
                    overlay.remove();
                }
            }
        }
    );
}



}
</script>
