<template>
	<div>
		<main class="home-page xl:mt-[9rem] md:mt-40 mt-32">
			<AboutMe />
			<TechSkills />
			<MySkills />
			<!-- <SomeRealisations /> -->
			<JustHaveQuestion />
			<NewsComponent />
			<MainFooter />
		</main>
		<i @click="scrollToTop" v-show="showScrollButton"
			class="bx bx-up-arrow-alt shadow-2xl w-9 h-9 border border-black bg-black text-white p-2 text-center rounded-tl-xl rounded-br-lg hover:bg-black hover:text-white text-[20px] fixed bottom-4 right-8"></i>
		<Seo :meta="seoMeta" />
	</div>
</template>

<script>
import AboutMe from "../components/Sections/AboutMe";
import TechSkills from "../components/Sections/TechSkills";
import MySkills from "../components/Sections/MySkills";
// import SomeRealisations from "../components/Sections/SomeRealisations";
import JustHaveQuestion from "../components/Sections/JustHaveQuestion";
import NewsComponent from '../components/NewsComponent'
import MainFooter from "../components/Footers/MainFooter";
import Seo from "../components/SeoComponent.vue";

export default {
	components: {
		AboutMe,
		TechSkills,
		MySkills,
		// SomeRealisations,
		JustHaveQuestion,
		NewsComponent,
		MainFooter,
		Seo
	},
	data() {
		return {
			showScrollButton: false,
			seoMeta: {
				title: 'Laurène Bitota - Portfolio',
				metaTags: [
					{ name: 'description', content: "Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace." },
					{ name: 'keywords', content: "Développeuse, Développement logiciel,Technologie,Transformation numérique,UI & UX,Gestion de Projets,Tendances technologiques,Solutions logicielles,Développement frontend,Sites Web Réactifs, Audit et Optimisation Web,Musique, Scrum, Lecture,Voyages,Communauté technologique,Kinshasa,RD Congo, RDC" },
					{ name: 'author', content: 'Laurène Bitota' },
					{ name: 'creator', content: 'Laurène Bitota' },
					{ name: 'robots', content: 'index, follow' },
					{ name: 'googlebot', content: 'index, nofollow, noimageindex, max-video-preview:-1, max-image-preview:large, max-snippet:-1' },
					{ property: 'og:title', content: 'Laurène Bitota' },
					{ property: 'og:description', content: "Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace." },
					{ property: 'og:url', content: 'https://www.laurenebitota.com/' },
					{ property: 'og:site_name', content: 'Laurène Bitota' },
					{ property: 'og:locale', content: 'fr_FR' },
					{ property: 'og:image', content: 'https://www.laurenebitota.com/banner.png' },
					{ property: 'og:type', content: 'website' },
					{ name: 'twitter:card', content: 'summary_large_image' },
					{ name: 'twitter:title', content: 'Laurène Bitota' },
					{ name: 'twitter:url', content: 'https://www.laurenebitota.com/' },
					{ name: 'twitter:description', content: "Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace." },
					{ name: 'twitter:image', content: 'https://www.laurenebitota.com/banner.png' },
					{ name: 'twitter:site', content: '@bitotabkl' }
				]
			}
		};
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		this.updateMeta(); // Call updateMeta method on mount
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		login() {
			this.$gtag.event('login', { method: 'Google' })
		},
		handleScroll() {
			if (window.scrollY > 20) {
				this.showScrollButton = true;
			} else {
				this.showScrollButton = false;
			}
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		},
		updateMeta() {
			const meta = this.seoMeta;
			if (!meta) return;

			document.title = meta.title || 'Default Title';

			if (meta.metaTags && Array.isArray(meta.metaTags)) {
				meta.metaTags.forEach(tag => {
					const tagElement = document.querySelector(`meta[name='${tag.name}']`) || document.querySelector(`meta[property='${tag.property}']`);
					if (tagElement) {
						tagElement.setAttribute('content', tag.content);
					} else {
						const newTag = document.createElement('meta');
						if (tag.name) newTag.setAttribute('name', tag.name);
						if (tag.property) newTag.setAttribute('property', tag.property);
						newTag.setAttribute('content', tag.content);
						document.head.appendChild(newTag);
					}
				});
			}
		}
	}
}
</script>

<style>
/* Loader style */
.loader {
	width: 48px;
	height: 48px;
	display: block;
	margin: 15px auto;
	position: relative;
	color: #ccc;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	width: 24px;
	height: 24px;
	top: 50%;
	left: 50%;
	transform: scale(0.5) translate(0, 0);
	background-color: #ccc;
	border-radius: 50%;
	animation: animloader 1s infinite ease-in-out;
}

.loader::before {
	background-color: #000;
	transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animloader {
	50% {
		transform: scale(1) translate(-50%, -50%);
	}
}

/* END Loader style */
</style>
