<template>
	<div class="min-h-screen">
		<div class="flex justify-center items-center h-screen" v-if="loading">
			<span class="loader"></span>
		</div>

		<div v-if="!loading" class="animate-bottom">
			<AppHeader class="block md:hidden" v-if="!is404Page"/>
			<AppMenu class="hidden md:block" v-if="!is404Page"/>
			<AppSidebar v-if="!is404Page"/>
			<router-view />
		</div>
	</div>
</template>

<script>
// Components
import AppHeader from './components/AppHeader'
import AppMenu from './components/AppMenu'
import AppSidebar from './components/AppSidebar'

export default {
	name: 'App',
	components: {
		AppMenu,
		AppHeader,
		AppSidebar
	},
	data() {
		return {
			loading: true,
			is404Page: false
		};
	},
	mounted() {
		setTimeout(() => {
			this.loading = false;
		}, 3000);
		this.updateMeta();

		this.$router.beforeEach((to) => {
			this.is404Page = to.name === 'Erreur'; 
			
		});
	},
	methods: {
		updateMeta() {
			const meta = {
				title: 'Laurène Bitota - Portfolio',
				metaTags: [
					{ name: 'description', content: "Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace." },
					{ name: 'keywords', content: "Développeuse, Développement logiciel,Technologie,Transformation numérique,UI & UX,Gestion de Projets,Tendances technologiques,Solutions logicielles,Développement frontend,Sites Web Réactifs, Audit et Optimisation Web,Musique, Scrum, Lecture,Voyages,Communauté technologique,Kinshasa,RD Congo, RDC" },
					{ name: 'author', content: 'Laurène Bitota' },
					{ name: 'creator', content: 'Laurène Bitota' },
					{ name: 'robots', content: 'index, follow' },
					{ name: 'googlebot', content: 'index, nofollow, noimageindex, max-video-preview:-1, max-image-preview:large, max-snippet:-1' },
					{ property: 'og:title', content: 'Laurène Bitota' },
					{ property: 'og:description', content: "Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace.Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace." },
					{ property: 'og:url', content: 'https://www.laurenebitota.com/' },
					{ property: 'og:site_name', content: 'Laurène Bitota' },
					{ property: 'og:locale', content: 'fr_FR' },
					{ property: 'og:image', content: 'https://www.laurenebitota.com/banner.png' },
					{ property: 'og:type', content: 'website' },
					{ name: 'twitter:card', content: 'summary_large_image' },
					{ name: 'twitter:title', content: 'Laurène Bitota' },
					{ name: 'twitter:url', content: 'https://www.laurenebitota.com/' },
					{ name: 'twitter:description', content: "Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace.Laurène Bitota, Développeuse web frontend spécialisée dans la création d'interfaces numériques innovantes. Avec mon approche axée sur les besoins des utilisateurs, je mets en œuvre des solutions web à la fois attractives et performantes, tout en assurant une gestion de projets efficace." },
					{ name: 'twitter:image', content: 'https://www.laurenebitota.com/banner.png' },
					{ name: 'twitter:site', content: '@bitotabkl' }
				]
			};
			document.title = meta.title;
			meta.metaTags.forEach(tag => {
				const tagElement = document.querySelector(`meta[name='${tag.name}']`);
				if (tagElement) {
					tagElement.setAttribute('content', tag.content);
				} else {
					const newTag = document.createElement('meta');
					newTag.setAttribute('name', tag.name);
					newTag.setAttribute('content', tag.content);
					document.head.appendChild(newTag);
				}
			});
		}
	}
}
</script>

<style>
/* Loader style */
.loader {
	width: 48px;
	height: 48px;
	display: block;
	margin: 15px auto;
	position: relative;
	color: #ccc;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	width: 24px;
	height: 24px;
	top: 50%;
	left: 50%;
	transform: scale(0.5) translate(0, 0);
	background-color: #ccc;
	border-radius: 50%;
	animation: animloader 1s infinite ease-in-out;
}

.loader::before {
	background-color: #000;
	transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes animloader {
	50% {
		transform: scale(1) translate(-50%, -50%);
	}
}

/* END Loader style */
</style>