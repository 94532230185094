<template>
  <div>
    <div
      class="block-form relative bg-slate-50 shadow-2xl p-10 md:w-5/12 md:p-6 md:absolute md:z-10 md:right-16 md:-top-8 lg:p-16 lg:-top-1 lg:right-24 lg:w-2/5">
      <form @submit.prevent="submitForm">
        <div class="flex flex-wrap">
          <div class="w-full mb-5">
            <input type="text"
              class="border-0 border-b bg-slate-50 px-0 py-3 p text-gray-900 placeholder:text-gray-600 focus:outline-none focus:shadow-none w-full ease-linear transition-all duration-150"
              placeholder="Votre nom" v-model="form.name" required />
          </div>
          <div class="w-full mb-5">
            <input type="email"
              class=" border-0 border-b bg-slate-50 px-0 py-3 p text-gray-900 placeholder:text-gray-600 focus:outline-none focus:shadow-none w-full ease-linear transition-all duration-150"
              placeholder="Email" v-model="form.email" required />
          </div>

          <div class="w-full mb-5">
            <input type="text"
                class=" border-0 border-b bg-slate-50 px-0 py-3 p text-gray-900 placeholder:text-gray-600 focus:outline-none focus:shadow-none w-full ease-linear transition-all duration-150"
                placeholder="Votre Budget (Optionnel)" v-model="form.budget" />
          </div>
        
          <div class="w-full mb-5">
            <textarea type="text"
              class="border-0 border-b bg-slate-50 px-0 py-3 p text-gray-900 placeholder:text-gray-600 focus:outline-none focus:shadow-none w-full ease-linear transition-all duration-150"
              placeholder="Description du Projet" rows="3" v-model="form.description" required />
          </div>
          <div class="w-full flex justify-start">
            <button
            class=" active:bg-black  shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear
            rounded-none uppercase bg-black px-3.5 py-2.5 text-white text-sm font-semibold border border-black hover:bg-white hover:text-black hover:border-black transition duration-300"
            type="submit">
            <span v-if="!submitting">Contactez-moi</span>
            <span v-else><i class="fa fa-spinner fa-spin"></i> Envoi en cours...</span>
          </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        budget: "",
        description: "",
      },
      submitting: false,
    };
  },
  methods: {
    async submitForm() {
      this.submitting = true;
      try {
        const response = await axios.post(
          "https://api.airtable.com/v0/appVwiPRW09uxRxLJ/Message?maxRecords=3&view=Grid%20view",
          {
            fields: {
              Name: this.form.name,
              Email: this.form.email,
              Budget: this.form.budget,
              Description: this.form.description,
            },
          },
          {
            headers: {
              Authorization:
                "Bearer pat4ZIOhrVw4BQRYA.14eee7ff115b602bf4bfba1bc8e4d9d43980acdd8b385e12c80a02c1f6929b4b",
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);

        toast("Votre message a été envoyé avec succès!", {
          theme: "auto",
          type: "success",
          position: "bottom-right",
          dangerouslyHTMLString: true,
        });
        this.resetForm();
      } catch (error) {
        console.error(error);
        toast(
          "Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer.",
          {
            theme: "auto",
            type: "error",
            position: "bottom-right",
            dangerouslyHTMLString: true,
          }
        );
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        budget: "",
        description: "",
      };
    },
  },
};
</script>

<style scoped>
.loading button {
  pointer-events: none;
  opacity: 0.6;
}
</style>