<template>
  <div class="w-full my-6">
    <div class="bg-slate-100 pt-28 pb-28 text-center">
      <p class="text-2xl lg:text-3xl font-medium mt-0 mb-2 text-gray-700 font-['Cormorant Upright']">
        Avez-vous un projet en tête ?
      </p>
      <h2 class="mt-2 mb-6 text-2xl md:text-3xl xl:text-4xl font-bold tracking-tight lg:mb-8 text-gray-900 font-['Poppins']">
        Dites simplement, Bonjour !
      </h2>
      
      <a href="mailto:bitotalaurene@gmail.com" class="rounded-none uppercase bg-black px-3.5 py-2.5 text-white text-sm font-semibold border border-black hover:bg-white hover:text-black hover:border-black transition duration-300">
        Contactez-moi <span class="ml-1">&#8594;</span>
      </a> 
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
