<template>
	<router-link 
		:to="to" 
		class="flex items-center p-4 text-gray-800 hover:text-black text-[16px] font-[500] uppercase">
		<slot />
	</router-link>
</template>

<script>
export default {
	props: ["to"]
}
</script>

<style scoped>
.router-link-exact-active {
	@apply text-black font-bold;
}
</style>

